.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #000 0%,
    #000 29%,
    orange 67%,
    #ff8800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 40px;
}


@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.floating {  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}
.post-body
{
  box-shadow: 0 0 75px rgb(0 0 0 / 10%);
  padding: 14px;
}
.post-image
{
  box-shadow:0 0 75px rgb(0 0 0 / 10%);
}
.display-spacing
{
  /* padding-top:60px !important;
  padding-bottom:60px !important; */
}

.section-portfolio-1 .portfolio-item:hover .image
{
  opacity: 1 !important;
  transform:none !important;
}

.portfolio-item
{
  padding: 10px !important;
  box-shadow:0 0 75px rgb(0 0 0 / 10%) !important;
}
.section-portfolio-1 .portfolio-item .portfolio-card img
{
  height: 280px !important;
}
.section-portfolio-1 .portfolio-item .portfolio-card
{
  padding-top:0px !important;
}
.title-block
{
  display: flex !important;
  height: 36px !important;
}
.title-block h3{

  padding: 10px;
  width: 80% !important;
  font-size: 18px !important;
  margin-bottom:0px !important ;
}
.portfoliocls .button 
{
  margin-top:15px !important;
}
.portfoliocls p
{
  padding: 0px 10px 0px 10px  !important;
  color:#808080;
  font-size: 12px!important;
  margin-bottom: 5px !important;
}
.el-headline
{
  margin-bottom: 20px !important;
}
