
/*------------------------------------
testimonial
------------------------------------*/
/* 
* testimonial 
*/

.detail-title {
    font-weight: 500;
    font-size: 35px;
    color: $secondary-orange;
}

.detail-description {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $white;
}

.project-details {
    padding: 5% 10%;
    background: #1D2331;

    .project-details-rows {
        display: flex;
        justify-content: center !important;
        img {
            height: 80px;
            // width: 80px;
        }
    }
    img {
        margin-right: 20px;
    }
}

.testimonial-slogan {
    position: absolute;
    bottom: 3%;
    text-align: center;
    width: 100%;
    padding: 0 3%;
}

.section-testimonial-1 {

    padding: 0 10% 0;

    .slick-slider {
        margin-bottom: 60px;
        margin-top: 5%;
    }

    .slick-dots {
        // top:0;
        // right: 15px;
        // bottom: inherit;
        // position: absolute;
        // text-align: right;
        // display: inline-block;
    }

    .col {
        padding-left: 0 !important;
        padding-right: 0 !important;

        img {
            width: 100%;
        }
    }
}
.section-testimonial-1 .testimonial-item {
    position: relative;
    // padding: 30px;
    // border: 1px solid $base-1;
    background-color: $dark-background;

    h5 {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .testimonial-name {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        display: flex;
        justify-content: space-between;
        color: $white;
        margin-top: 2%;

        &__name {
            width: 58%;
        }

        img {
            display: inline-block;
            margin: 0 2px;
        }
    }

    .testimonial-image {
        width: 150px;
        height: 150px;
        border-radius: 40px 40px 0px 40px;
    }

    .testimonial-clients-image-section {
        display: flex;
        justify-content: end;
    }

    .default-image-name {
        background: $secondary-orange;
        color: $dark-background;
        text-align: center;
        margin: auto 0;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 50px;
        text-transform: uppercase;
        font-weight: 700;
    }
   
}
.section-testimonial-1 .testimonial-item p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
    // position: absolute;
    // bottom: 0;
}
.section-testimonial-1 .testimonial-item .type {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    margin: 0 15px 30px;
}
.section-testimonial-1 .testimonial-item .type span {
    z-index: 6;
    position: relative;
}
.section-testimonial-1 .testimonial-item .type:after {
    left: -16px;
    bottom: -6px;
    width: 36px;
    height: 36px;
    opacity: 0.75;
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: $primary;
}
.section-testimonial-1 .testimonial-item .author {
    height: 50px;
    position: relative;
    padding-left: 65px;
}
.section-testimonial-1 .testimonial-item .author h4 {
    font-size: 14px;
    margin-bottom: 6px;
}
.section-testimonial-1 .testimonial-item .author h5 {
    color: $base-1;
    font-size: 12px;
    margin-bottom: 0;
}
.section-testimonial-1 .testimonial-item .author img {
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    border-radius: 50%;
}
.section-testimonial-1 .lets-work {
    text-align: center;
    padding-top: 90px;
    position: relative;
}
.section-testimonial-1 .lets-work .button {
    top: 0px;
}

@media (max-width: 992px) {
    .section-testimonial-1 .shape-2 {
        display: none;
    }

    .section-testimonial-1 .testimonial-item p {
        position: relative;
    }

    .project-details .project-details-rows {
        margin-bottom: 20px;
    }

    .section-testimonial-1 .testimonial-item {
        .testimonial-clients-image-section {
            display: block;
        }
    }

    .project-details {
    
        .project-details-rows {
            // display: flex;
            justify-content: left !important;
            img {
                height: 50px;
                // width: 80px;
            }
        }
    }

    .testimonial-slogan {
        position: absolute;
        bottom: -3%;
        text-align: center;
        width: 100%;
        padding: 0 3% 3%;
    }
}
@media (max-width: 768px) {
    .section-testimonial-1 .testimonial-item {
        padding: 20px;
    }
    .section-testimonial-1 .testimonial-item p {
        font-size: 12px;
    }
}

.section-testimonial-1 .testimonial-item p {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

@media (max-width: 1500px) {
    .section-testimonial .display-spacing {
        padding: 120px 4% 0;
    }

    .section-testimonial-1 .testimonial-item .testimonial-image {
        width: 110px;
        height: 110px;
    }

    .section-testimonial-1 .testimonial-item {
        .default-image-name {
            font-size: 35px;
        }
    }
}

@media (min-width:1200px) and (max-width: 1250px) {
    .section-testimonial-1 .testimonial-item .testimonial-name {
            font-size: 20px;
    }
}

@media (max-width: 450px) {
    .section-testimonial-1 .testimonial-item {
        padding: 4px;

        .testimonial-name {
            font-size: 19px;
        }
    }
   
}