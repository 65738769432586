/*------------------------------------
    Helpers
------------------------------------*/

// Text Helpers
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.lowercase {
    text-transform: lowercase;
}
.uppercase {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}
.text-italic {
    font-style: italic;
}
.text-normal {
    font-style: normal;
}
.text-underline {
    font-style: underline;
}

/*
* Text Colors
*/
.text-dark {
    color: $black;
}
.text-dark-1 {
    color: #333333;
}
.text-dark-2 {
    color: #666666;
}
.text-dark-3 {
    color: #999999;
}
.text-light {
    color: $white;
}
.text-light-1 {
    color: #eeeeee;
}
.text-light-2 {
    color: #cccccc;
}
.text-light-3 {
    color: #aaaaaa;
}
.text-base-0 {
    color: $base-0;
}
.text-base-1 {
    color: $base-1;
}
.text-base-1 {
    color: $base-2;
}
.text-primary {
    color: $primary;
}
.text-secondary {
    color: $secondary;
}
.text-gradient {
    background-image: linear-gradient(to left, $primary, $secondary);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

// Other

.hidden {
    display: none;
}
.invisible {
    visibility: hidden;
}
.div-center {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.inline-block {
    float: none;
    display: inline-block;
}
.clearfix {
    @include clearfix();
}

// Display Helpers
.display-wrap {
    position: relative;
}
.display-block {
    display: block;
}
.display-center {
    top: 55%;
    left: 50%;
    width: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
}
.display-table {
    width: 100%;
    height: 100%;
    display: table;
}
.display-content {
    display: table-cell;
    vertical-align: middle;
}
.display-spacing {
    padding-top: 140px;
    padding-bottom: $space;
}
.display-screen {
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
}
.display-fit-screen {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    overflow: hidden;
}
.display-full-screen {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    position: relative;
}

// Max Width
.max-w-576 {
    max-width: 576px;
}
.max-w-768 {
    max-width: 768px;
}
.max-w-992 {
    max-width: 992px;
}

// Font Weight
@each $i in (1, 2, 3, 4, 5, 6, 7, 8, 9) {
    $j: $i * 100;
    .fw-#{$j} {
        font-weight: $j;
    }
}

// Overlay
.overlay-color {
    @include overlay("color");
}
.overlay-image {
    @include overlay("image");
}
.overlay-pattern {
    @include overlay("pattern");
}
.overlay-gradient {
    @include overlay("gradient");
}

.header-number-email {
    padding-left: 8%;
    padding-right: 8%;
    background: #FF9C4F;
    color: black;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    padding: 5px 4%;

    .number {
        svg {
            position: relative;
            top: 4px;
            margin-right: 6px;
        }
    }

    .email {
        svg {
            position: relative;
            top: 6px;
            margin-right: 6px;
        }
    }

    @media (max-width: 1024px) {
        display: none;
    }
}

.header-number-email-mobile {
    padding-left: 8%;
    padding-right: 8%;
    margin-top: 10%;
    // background: #FF9C4F;
    color: black;
    // display: flex;
    // justify-content: space-between;
    font-size: 15px;
    padding: 5px 4%;

    .number {
        display: flex;
        svg {
            // position: relative;
            // top: 4px;
            margin-right: 6px;
        }
    }

    .email {
        display: flex;
        margin-top: 5px;
        svg {
            // position: relative;
            // top: 6px;
            margin-right: 6px;
        }
    }
}


// Background Colors
.bg-primary {
    background-color: $primary;
}
.bg-secondary {
    background-color: $secondary;
}
.bo-color-1 {
    border-color: $color-1;
}
.bo-color-2 {
    border-color: $color-2;
}
.bo-color-3 {
    border-color: $color-3;
}
.bo-color-4 {
    border-color: $color-4;
}
.bo-color-5 {
    border-color: $color-5;
}
.bo-color-6 {
    border-color: $color-6;
}
.bo-primary {
    border-color: $primary;
}
.bp-secondary {
    border-color: $secondary;
}
.bg-color-1 {
    background-color: $color-1;
}
.bg-color-2 {
    background-color: $color-2;
}
.bg-color-3 {
    background-color: $color-3;
}
.bg-color-4 {
    background-color: $color-4;
}
.bg-color-5 {
    background-color: $color-5;
}
.bg-color-6 {
    background-color: $color-6;
}
.bg-light {
    background-color: $white;
}
.bg-dark {
    background-color: $black;
}
.bg-base-0 {
    background-color: $base-0;
}
.bg-base-1 {
    background-color: $base-1;
}
.bg-primary {
    background-color: $primary;
}
.bg-secondary {
    background-color: $secondary;
}
.bg-gradient-side {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to($primary));
    background-image: linear-gradient(270deg, $primary 0%, transparent 70%);
}
@each $i in (1, 2, 3, 4, 5, 6, 7, 8, 9, 100) {
    $j: calc($i / 10);
    .bg-dark-#{$i}0 {
        background-color: rgba(0, 0, 0, $j);
    }
}
@each $i in (1, 2, 3, 4, 5, 6, 7, 8, 9, 100) {
    $j: calc($i / 10);
    .bg-light-#{$i}0 {
        background-color: rgba(255, 255, 255, $j);
    }
}
@each $i in (0, 45, 90, 135, 180, 225, 270, 315, 360) {
    .bg-gradient-#{$i} {
        @include bg-gradient($i);
    }
}

@media (max-width: 991px) {
    .display-center {
        position: unset;
        transform: unset;
    }

    .display-spacing {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}