/*------------------------------------
    Header
------------------------------------*/
// .loader {
//     position: absolute;
//     top: calc(50% - 4em);
//     left: calc(50% - 4em);
//     width: 6em;
//     height: 6em;
//     border: 1.1em solid rgba(0, 0, 0, 0.2);
//     border-left: 1.1em solid #000000;
//     border-radius: 50%;
//     animation: load8 1.1s infinite linear;
//     transition: opacity 0.3s;
//   }
  
  .loader--hide {
    display: none;
  }
  .loader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .bar {
    width: 10px;
    height: 70px;
    background: #fff;
    display: inline-block;
    transform-origin: bottom center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    /*   box-shadow:5px 10px 20px inset rgba(255,23,25.2); */
    animation: loader 1.2s linear infinite;
  }
  .bar1 {
    animation-delay: 0.1s;
  }
  .bar2 {
    animation-delay: 0.2s;
  }
  .bar3 {
    animation-delay: 0.3s;
  }
  .bar4 {
    animation-delay: 0.4s;
  }
  .bar5 {
    animation-delay: 0.5s;
  }
  .bar6 {
    animation-delay: 0.6s;
  }
  .bar7 {
    animation-delay: 0.7s;
  }
  .bar8 {
    animation-delay: 0.8s;
  }
  
  @keyframes loader {
    0% {
      transform: scaleY(0.1);
      background: #fec070;
    }
    50% {
      transform: scaleY(1);
      background: #ff8800;
    }
    100% {
      transform: scaleY(0.1);
      background: transparent;
    }
  }
// Header 1
.app-header-1 {
    background: $dark-background;
    z-index: 99;
    width: 100%;
    max-width: 100%;
    position: fixed;

    .header-logo {
        top: 20px;
        left: 30px;
        z-index: 12;
        font-size: 24px;
        font-weight: 700;
        position: absolute;
    }

    .name {
        color: $base-0;
        margin: auto 0;
        font-size: 20px;
    }

    .menu-text {
        // float: right;
        .button-menu {
            display: none;
        }
    }

    .header-menu {
        display: flex;
        justify-content: space-between;
        background: $dark-background;

        left: 0;
        right: 0;
        // bottom: 0;
        // text-align: center;
        position: fixed;
        list-style: none;
        overflow: auto;
        white-space: nowrap;
        padding-left: 8%;
        padding-right: 8%;
        padding-top: 1%;
        padding-bottom: 1%;
        margin-bottom: 0;
        // background: $base-1;
        -ms-overflow-style: none;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;

        a {
            margin: auto 0;
            display: flex;

            img {
                height: 30px;
                margin-right: 10px;
            }
        }

        li {
            color: $base-0;
            font-size: 16px;
            margin-right: 25px;
            display: inline-block;
            padding: 7px 0 6px;

            .active {
                color: $primary;
                font-weight: 700;
            }
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .header-dots {
        top: 50%;
        right: 30px;
        width: 20px;
        height: 300px;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        position: fixed;
        list-style: none;
        margin-top: -150px;

        li {
            margin-bottom: 14px;

            a {
                display: block;

                .circle {
                    width: 10px;
                    height: 10px;
                    display: block;
                    border-radius: 50%;
                    border: 1px solid $base-1;
                }
                &.active {
                    .circle {
                        border-color: $primary;
                        background-color: $primary;
                    }
                }
            }
        }
    }
    .button-menu {
        // top: 10px;
        // right: 30px;
        // width: 50px;
        // height: 50px;
        // background: linear-gradient(180deg, #1488CC 0%, #2B32B2 100%);
        // box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
        // border-radius: 50%;
        // z-index: 99;
        // position: absolute;
        // padding-top: 12px;

        span {
            width: 36px;

            &,
            &:before,
            &:after {
                height: 3px;
                float: right;
                display: block;
                position: relative;
                background-color: $base-1;
            }
            &:before {
                top: -12px;
                width: 30px;
                content: "";
            }
            &:after {
                bottom: -10px;
                width: 30px;
                content: "";
            }
        }
    }
    .header-follow {
        // top: 50%;
        // left: 30px;
        // width: 20px;
        // height: 200px;
        // position: fixed;
        // margin-top: -100px;
        display: flex;
        justify-content: center;
        padding-top: 5%;

        .line {
            width: 40px;
            height: 4px;
            background-color: $secondary-orange;
            margin: auto 5px;
            border-radius: 2px;
        }   

        p {
            // top: 70px;
            // left: -24px;
            // width: 68px;
            // height: 20px;
            color: $white;
            font-size: 25px;
            font-weight: 600;
            line-height: 1.2;
            white-space: nowrap;
            position: relative;
            margin-bottom: 0;
            // transform: rotate(-90deg);

            // &:after {
            //     top: 7px;
            //     right: -48px;
            //     width: 40px;
            //     height: 1px;
            //     content: "";
            //     display: block;
            //     position: absolute;
            //     background-color: $base-1;
            // }
        }
    }
}

@media (max-width: 1024px) {

    .app-header-1 {
        .header-menu {
            height: 60px;
        }
        
        .menu-text {
            margin: auto 0;
            // float: right;
            .button-menu {
                display: flex;
            }
        }
        }

    .menu-text {
        li {
            display: none !important;
        }

        .button-menu {
            display: block;
        }
    }

    .app-header {
        .header-logo {
            top: 15px;
            left: 15px;
        }
        .header-dots {
            right: 15px;
        }
        .button-menu {
            // top: 15px;
            // right: 15px;
        }

        .header-follow {
            left: 15px;
        }
    }
}

@media (max-width: 992px) {
    .app-header {
        position: absolute !important;

        .header-logo {
            top: 14px;
            left: 18px;
        }
        .button-menu {
            // top: 7px;
            // right: 10px;
        }
        .header-dots {
            right: 0 !important;
        }
        .header-follow {
            left: 10px;
        }
    }

    .app-header-1 {
        .header-follow {
            padding-bottom: 2%;
            padding-top: 2%;
        }
    }
}

@media (max-width: 768px) {
    .app-header {
        .header-dots,
        .header-follow {
            display: none !important;
        }
    }
}

@media (max-width: 550px) {
.app-header-1 {
    .name {
        display: none;
    }
}
}