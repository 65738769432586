/*------------------------------------
    Hero
------------------------------------*/

.section-hero {
    width: 100%;
    position: relative;
    overflow-x: hidden;
}

.section {
    background: $dark-background;
    overflow-x: hidden;
}

.ti-github {
    font-size: 26px;
    margin-left: 10px;
    color: white;
}

.ti-linkedin {
    font-size: 26px;
    margin-left: 10px;
    color: white;
}

.ti-write {
    font-size: 30px;
    // margin-left: 10px;
    color: $secondary-orange;
    margin: auto;
}

.ti-pencil {
    font-size: 30px;
    // margin-left: 10px;
    color: $secondary-orange;
    margin: auto;
}

.ti-medall {
    font-size: 38px;
    // margin-left: 10px;
    color: $secondary-orange;
    margin: auto;
}

// Hero 1
.section-hero-1 {

    .intro-image {
        position: relative;
    }

    .intro-image .happyClientimg {
        position: absolute;
        top: 130px;
        left: -95px;
        width: 150px;
    }

    .intro-image .yearsExperiencs {
        position: absolute;
        left: -30px;
        bottom: 30px;
        width: 150px;
    }

    .intro-image .bestServices {
        position: absolute;
        top: 200px;
        right: -95px;
        width: 150px;
    }

    .intro-text-container {
        margin: auto;

        h2 {
            font-size: 55px;
        }

        .intro-username {
            color: $secondary-orange;
        }
    }

    .el-heading {
            .el-icon {
                margin-bottom: 20px;
            }
    
            .intro-image-section {
                display: flex;
            }

            .intro-image-section .button {
                margin: 12px 0;
            }
    
            .arrow-images {
                width: auto;
                margin: 3% 2%;
            }
    
            .intro-image-technology {
                width: 72px;
                height: 72px;
                border-radius: 50%;
                background: #1D2331;
                display: flex;
                margin: 0 5px;
            }
    
            .intro-image-technology img {
                margin: auto;
                justify-content: center;
                width: 32px;
            }
        }

    .intro-image {
        width: 350px;
        margin: auto;
    }
}

// Hero 2
.section-hero-2 {
    .el-heading {
        float: right;

        .el-icon {
            margin-bottom: 20px;
        }
    }
}

// Hero 4
.section-hero-4 {
    .el-slider {
        width: 100%;
        height: 100vh;

        .slide {
            .image {
                height: 100vh;
            }
        }
        .slick-dots {
            z-index: 6;
            bottom: 60px !important;
        }
    }
    .el-particles {
        opacity: 0.3;
    }
    .display-center {
        z-index: 3;
    }
}

// Hero 5
.section-hero-5 {
    .el-icon {
        .icon {
            position: relative;
            margin-left: 3px;
            animation: MoveUpDown 1s linear infinite;
        }
    }
    .el-heading {
        width: 100% !important;
        max-width: 100% !important;

        h1 {
            font-size: 42px;
        }
    }
}

// Hero 6
.section-hero-6 {
    &:before {
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        z-index: 15;
        opacity: 0.75;
        content: "";
        position: fixed;
        background: $base-0;
    }
    .display-center {
        z-index: 18;
    }
}

@media (max-width: 768px) {

    .app-header-1 .header-follow p {
        font-size: 20px;
    }

    .el-icon .el-icon-title {
        font-size: 25px;
        margin-top: 1%;
        margin-bottom: 3%;
    }

    .section-intro-1 {
        padding: 70px 3% 100px 3% !important;
    }

    .intro-text-container {
        h2 {
            font-size: 30px !important;
        }
    }
        .button-lg {
            height: 45px;
            font-size: 14px !important;
            padding: 12px 20px !important;
        }
    .section-hero-2 {
        background-position: center left;
        .el-heading {
            float: inherit !important;
        }
        .display-center {
            z-index: 3;
        }
        &:after {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            opacity: 0.45;
            position: absolute;
            background-color: $base-0;
        }
    }
    .section-hero-5 {
        .el-heading {
            h1 {
                font-size: 24px !important;
            }
        }
    }
}

@media (max-width: 500px) {
    .section-hero-1 {
    .el-heading {
        .el-icon {
            margin-bottom: 5px;
        }

        .intro-image-technology {
            width: 60px;
            height: 60px;
        }

        .intro-image-technology img {
            width: 25px;
        }
    }
    }
}

@media (max-width: 991px) {
    .section-hero-1 {

        .intro-image {
            .ruchita-img {
                width: 75%;
            }
        }
    
        .intro-image {
            .happyClientimg {
                position: absolute;
                top: 70px;
                left: -30px;
                width: 116px;
            }

            .yearsExperiencs {
                left: -2px;
                bottom: 30px;
                width: 116px;
             }

             .bestServices {
                top: 153px;
                right: -30px;
                width: 116px;
             }
        }   
    }
}

@media (max-width: 405px) {
    .section-hero-1 {
    
        .intro-image {
            .happyClientimg {
                width: 90px;
                left: 0px;
            }

            .yearsExperiencs {
                left: 25px;
                width: 90px;
             }

             .bestServices {
                right: 5px;
                width: 90px;
             }
        }   
    }
}
