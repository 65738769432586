/*------------------------------------
    Section: Portfolio
------------------------------------*/

.section-portfolio-1 {
    // background-color: $base-0;

    .portfolio-item-section {
        margin: 0 10%;
    }

    .portfolio-item {
        overflow: hidden;
        position: relative;
        margin-bottom: 30px;
        background: #1D2331;

        .project-name {
            font-size: 30px;
            color: #FFFFFF;
            font-family: 'Inter';
            font-weight: 600;
        }

        .project-workType {
            font-size: 15px;
            line-height: 18px;
            color: #D9D9D9;
            margin: 10px 0;
        }

        .portfolio-card-text {
            margin: 20px;
        }

        .portfolio-button-section {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 10px;
        }

        .portfolio-card {
            width: 100%;
            overflow: hidden;
            position: relative;
            // padding-top: 100%;

            .portfolio-description {
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 15px;
                line-height: 18px;
                color: #FFFFFF;
                
                p {
                    margin-bottom: 0px;
                    line-height: 18px;
                }
            }

            .image {
                // top: 0;
                // left: 0;
                // width: 100%;
                // height: 100%;
                // position: absolute;
                // width: 417px;
                height: 214.29px;
                transition: all 600ms;
            }
            .content {
                top: 60%;
                left: 50%;
                width: 100%;
                opacity: 0;
                visibility: hidden;
                position: absolute;
                text-align: center;
                transition: all 450ms;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

                h3 {
                    color: $base-1;
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 0;
                }
                span {
                    color: $base-1;
                    display: block;
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    margin-bottom: 6px;
                }
                .button {
                    opacity: 0;
                    margin-bottom: 30px;
                    transform: translate(0, -60px);
                    transition: all 450ms;

                    span {
                        top: 4px;
                        left: 1px;
                        font-size: 14px;
                        position: relative;
                    }
                }
            }
        }

        &:hover {
            transform: scale(1.03, 1.03);
            cursor: pointer;

            .image {
                // opacity: 0;
                // transform: scale(1.2, 1.2);
            }

            .portfolio-card {
                // transform: scale(1.2, 1.2);
            }
            .content {
                top: 50%;
                opacity: 1;
                visibility: visible;

                .button {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .portfolio-item-section {
        margin: 0 3% !important;
    }

    .section-portfolio-1 {
        .el-tabs-links {
            li {
                font-size: 10px;
                padding-left: 6px;
                padding-right: 6px;
            }
        }
        .row-center {
            margin-left: -6px !important;
            margin-right: -6px !important;
            margin-bottom: -12px !important;
            > * {
                padding-left: 6px !important;
                padding-right: 6px !important;
            }
        }
        .portfolio-item {
            margin-bottom: 20px;
            
            p {
                font-size: 12px;
            }
            h4 {
                font-size: 14px;
            }
        }
    }
}
