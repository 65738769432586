/*------------------------------------
    Section: Contact
------------------------------------*/

.section-contact-1 {
    padding: 0 3%;

    .contact-content {
        width: 80%;
        margin: auto;

        .submit-button {
            background-color: $primary;
            border-radius: 15px;

           &:hover {
            cursor: not-allowed;
           } 
        }
    }

    .contact-slogan {
        margin-top: -2%;
    }

    .contact-image {
        height: 50%;
        position: relative;
        text-align: center;
        margin-bottom: 40px;

        .image {
            top: 0;
            left: 15px;
            right: 15px;
            height: 100%;
            position: absolute;
        }

        .contactimag
        {
            height: 100%;
        }
    }
}

@media (max-width: 992px) {
    .section-contact-1 {
    
        .contact-content {
            width: 100%;
        }
    }
}