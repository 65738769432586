.home-slogan {
    // margin-top: 2%;
    position: absolute;
    bottom: 3%;
    width: 100%;
}

@media (max-width: 992px) {
    .home-slogan {
        // margin-top: 2%;
        position: unset;
        width: 100%;
        margin-top: 30px;
    } 

    // .section-intro-1 {
    //     padding: 100px 3% 100px 3% !important;
    // }
}

.section-about-1 {
    padding: 0 3%;
    .about-image {
        height: 100%;
        position: relative;

        .image {
            
            display: flex;
            justify-content: center;

            img {
                max-height: 650px;
                margin-bottom: 6%;
            }
        }
    }
}

.section-resume-1 {

    .resume-education-col {
        left: -50px !important;
    }

    .resume-experience-col {
        right: -50px !important;
    }

    .resume-experience {
        margin-top: 46% !important;
    }

    .first-round-circle {
        display: flex;
        position: absolute;
        width: 70px;
        height: 70px;
        /* left: 676px; */
        left: 47%;
        top: 12%;
        background: $dark-background;
        border-radius: 50%;
        border: 1px solid $secondary-orange;
    }

    .second-round-circle {
        display: flex;
        position: absolute;
        width: 70px;
        height: 70px;
        /* left: 676px; */
        left: 47%;
        bottom: 47%;
        background: $dark-background;
        border-radius: 50%;
        border: 1px solid $secondary-orange;
    }

    .thired-round-circle {
        display: flex;
        position: absolute;
        width: 70px;
        height: 70px;
        /* left: 676px; */
        left: 47%;
        bottom: 12%;
        background: $dark-background;
        border-radius: 50%;
        border: 1px solid $secondary-orange;
    }

    hr {
        border: 1px solid $secondary-orange;
        position: absolute;
        margin: auto;
        left: 50%;
        height: 100%;
    }

    .resume-row {
        background: #1D2331;
        border-radius: 10px;
        padding: 20px;
        margin: 10px;
        
        h4 {
            color: $secondary-orange;
            padding: 10px 0;
            margin-bottom: 0;
        }

        h5 {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #FFFFFF;
            margin-bottom: 0;
        }

        h6 {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: #9D9D9D;
        }

        .resume-item p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #FFFFFF;

            p {
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            display: block;
            position: relative;

            // &:after {
            //     top: -18px;
            //     left: 0;
            //     width: 1px;
            //     height: 100%;
            //     content: "";
            //     position: absolute;
            //     background-color: $base-1;
            // }
        }
    }
    .resume-title {
        z-index: 3;
        line-height: 1;
        position: relative;
        padding-left: 35px;

        // &:before {
        //     top: 5px;
        //     left: -7px;
        //     width: 15px;
        //     height: 15px;
        //     display: block;
        //     content: "";
        //     position: absolute;
        //     border-radius: 50%;
        //     background-color: $primary;
        // }
    }
    .resume-item {
        position: relative;

        p {
            // font-size: 12px;
            // font-weight: 500;
            // padding-left: 10px;
            // margin-bottom: 0;
        }
        .resume-body {
            // padding: 20px 36px;
        }
        .resume-head {
            position: relative;
            // padding-top: 30px;
            // padding-left: 90px;
            // margin-bottom: 10px;

            h5 {
                // color: $base-1;
                // font-size: 14px;
                // font-weight: 700;
                // margin-bottom: 10px;
            }
            h6 {
                // color: $base-1;
                // font-size: 12px;
            }
            .el-icon {
                top: 26px;
                left: 24px;
                position: absolute;
            }
        }
        // &:before {
        //     top: 43px;
        //     left: 0;
        //     width: 25px;
        //     height: 1px;
        //     content: "";
        //     position: absolute;
        //     background-color: $base-1;
        // }
    }
}

@media (max-width: 1250px) {

    hr {
        display: none;
    }
    .section-resume-1 { 

        .resume-experience {
            margin-top: 10px !important;
        }

        .first-round-circle {
            display: none;
        }

        .second-round-circle {
            display: none;
        }

        .thired-round-circle {
            display: none;
        }

        .resume-education-col {
            left: auto !important;
        }
    
        .resume-experience-col {
            right: auto !important;
        }
    }
   
}
