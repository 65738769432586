/*------------------------------------
    Section: Service
------------------------------------*/

.section-service {

    .itemDevide {
        

        @media (min-width: 1150px) {
            margin-top: 250px;    
        }
    }

    .h-horizontal-0 {
        margin-bottom: 0;
    }

    .hr-0 {
        left: 50%;
        border-left: 1px solid $secondary-orange;
        position: absolute;
        bottom: -135px;
        height: 120px;
    }

    .h-horizontal-1 {
        left: -62%;
        border-bottom: 1px solid $secondary-orange;
        position: absolute;
        width: 177px;
        bottom: 52%;
        margin-bottom: 0;
    }

    .hr-1 {
        left: 50%;
        border-left: 1px solid $secondary-orange;
        position: absolute;
        height: 140px;
        top: -136px;
    }

    .h-horizontal-2 {
        left: -62%;
        border-bottom: 1px solid $secondary-orange;
        position: absolute;
        width: 177px;
        bottom: 54%;
        margin-bottom: 0;
    }

    .hr-2 {
        left: 50%;
        border-left: 1px solid $secondary-orange;
        position: absolute;
        bottom: -135px;
        height: 120px;
    }

    .h-horizontal-3 {
        left: -62%;
        border-bottom: 1px solid $secondary-orange;
        position: absolute;
        width: 177px;
        bottom: 52%;
        margin-bottom: 0;
    }
    
    .hr-3 {
        margin-bottom: 0;
    }

    .service-wrap {
        position: relative;
        padding-top: 100%;

        .service-item {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            padding: 20px;
            // border: 1px solid #000;
            
            background: #151B24;
            border: 1px solid $white;
            border-radius: 17px;

            img {
                margin: auto;
                position: relative;
                top: 13px;
            }

            p {
                // font-size: 12px;
                display: block; /* Fallback for non-webkit */
                display: -webkit-box;
               // height: 2.6em; /* Fallback for non-webkit, line-height * 2 */
                // line-height: 1.3em;
                -webkit-line-clamp: 4; /* if you change this, make sure to change the fallback line-height and height */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $white; 
                margin-bottom: 0;

                p {
                    margin-bottom: 0;
                }
            }
            h3 {
                font-family: 'Inter';
                display: block;
                font-size: 30px;
                font-weight: 600;
                padding-top: 15px;
                margin-bottom: 15px;
            }
            .el-icon-icon {
                top: 30px;
                left: 30px;
                width: 36px;
                height: 36px;
                font-size: 30px;
                position: absolute;
                text-align: center;
            }

            @media (min-width: 1150px) {
                &:hover {
                    background-color: $primary;
                    border: 1px solid #151B24;
                    cursor: pointer;
                    
                    p, h3{
                        color: #151B24;
                    }
    
                    .service-head .image-container {
                        background: #1D2331;
                    }
                }   
            }
            
        }
    }
}

// Service 3
.section-service-3 {
    .service-wrap {
        .service-item {
            background-color: $primary;
        }
    }
}

.section-service-5 {
    .service-head .image-container {
        height: 70px;
        width: 70px;
        background: #FF9C4F;
        border-radius: 20px 20px 0px 20px;

        .icon-3 {
            height: 44px;
        }
    }

    @media (max-width: 1200px) {
        .slick-list {
            margin: 10px;
        }        
    }

}

@media (max-width: 768px) {
    .section-service {
        .service-wrap {
            padding-top: 20px;
            .service-item {
                padding: 10px 20px 0;
                position: relative;

                img {
                    display: flex;
                }

                h3 {
                    margin-bottom: 10px;
                }
                .el-icon-icon {
                    top: 15px;
                    left: 15px;
                }

                p p {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .section-service {
        .service-wrap {
            padding-top: 20px;
            
            .service-item {
                padding: 30px 30px 0;

                h3 {
                    margin-bottom: 10px;
                }
                .el-icon-icon {
                    top: 30px;
                    left: 30px;
                }
            }
        }
    }
}
